/**
 * Giftcards /shop (aka search results)
 */

'use strict';

const urlVars = require('Scripts/common/url-vars');
const PreShop = require('Scripts/giftcards/pre-shop');

module.exports = Shop;

function Shop() {

	this.init = function() {
		this.fillFormWithQs();

		// Paging parameters. These are not loaded from the query string anymore.
		this.pageSize = 12;
		this.currPage = 1;

		this.bind();
		this.load(false); // No 2nd param cos it's an initial load

		// Variables to track loading moar, get nicer behaviour
		this.loadMoarMargin = 400;
		this.loadingMoar = false;
	}

	// Called every time a new page of data loads
	this.initPaging = function() {
		$("#loadmore").hide();
		self.nResults = $("#results-found").data("value");
		self.maxPages = Math.ceil(
			self.nResults / self.pageSize
		);

		// Detect when the user has scrolled to the bottom of the page. Increment page and load new when that happens.
		$(window).unbind("scroll");
		$(window).bind("scroll", function() {
			var scrollTop = $(window).scrollTop();
			var cutoff = ($(document).height() - $(window).height()) - self.loadMoarMargin;
			if(scrollTop >= cutoff && !self.loadingMoar) {
				if(self.currPage >= self.maxPages) {
					return; // Reached the end, no more pages to load
				}
				self.loadingMoar = true;
				$("#loadmore").show();
				self.currPage++;
				self.load(true);
			}
		});
	}

	// Given the query string, fill the form out with values
	// Happens when user presses back button or otherwise loads a page with some query parameters
	this.fillFormWithQs = function() {
		var params = new urlVars();

		// Apply cats
		$(".filter-cat").each(function() {
			$(this).prop("checked", false);
		});
		
		$(".filter-cat--modal").each(function() {
			$(this).prop("checked", false);
		});

		if("cats" in params) {
			for (var i = 0; i < params["cats"].length; i++) {
				var cat = params["cats"][i];
				$("#filter-cat_"+cat).prop("checked", true);
				$("#filter-cat--modal_"+cat).prop("checked", true);
			}
		}

		// Apply type/multi (online/instore option not in API any more so commented out)
		if("multi_only" in params && params["multi_only"] == 1) {
			$("#filter-type_multi").prop("checked", true);
		}

		// Apply sorting parameter
		var sort = ("sort" in params) ? params["sort"] : "popular" // Default param
		$("#sort-order").val(sort);

		// Apply name parameter
		if("name" in params) {
			// Name must be decoded to handle spaces etc.
			var name = decodeURIComponent(params["name"].replace(/\+/g, " "));
			
			$("#nav-search").val(name);
		}
	}

	// Bind each form element to a handler. This includes checkboxes that appear in both the full width category / type checkbox panel and the mobile filter modal
	this.bind = function() {
		// Category checkboxes
		self.bindCheckboxesByClass("filter-cat");

		// Digital or reload checkboxes
		self.bindCheckboxesById("filter-type_all");
		self.bindCheckboxesById("filter-type_multi");

		// This is the search bar. disable the default action so we can get the custom store search for c4c
		$(".search--box").submit(function() {
			self.load(false, true);
			return false;
		});

		// Sort order drop down menu
		$("#sort-order").change(function() {
			self.load(false, true);
		});

		// Submit button on mobile modal
		$("#filter-modal-button").click(self.modalSubmit);

		// Back or forward button clicks - this will change the query string. When the query string changes, we parse the qs into the form and then load using the form
		$(window).on("popstate", function() {
			self.fillFormWithQs();
			self.load(false);
		});
	}

	this.modalSubmit = function() {
		$('#modal-refine').modal('hide');
		self.load(false, true);
	}

	// Category checkboxes
	this.bindCheckboxesByClass = function(key) {
		$("."+key).change(function() {
			self.catChange($(this), key, true);
			return false;
		});
	
		$("."+key+"--modal").change(function() {
			self.catChange($(this), key, false);
			return false;
		});
	}

	// Type checkboxes (online or instore)
	this.bindCheckboxesById = function(key) {
		$("#"+key).change(function() {
			self.checkChange($(this), key, true);
			return false;
		});

		$("#"+key+"--modal").change(function() {
			self.checkChange($(this), key, false);
			return false;
		});
	}

	this.load = function(isPaging, isAjax) {
		if(!isPaging) {
			self.currPage = 1;
		}

		var data = self.buildRequest();

		// Add the from and limit clauses to the request parameters
		data["from"] = (self.currPage - 1) * self.pageSize;
		data["size"] = self.pageSize;

		var uriComponent = decodeURIComponent($.param(data));

		// One must be careful here... don't push state unless it's the >1th ajax request
		if(window.history.pushState && isAjax) { // Update URL query string with parameters
			var newUrl = "/shop?"+uriComponent;
			window.history.pushState({state: "new"}, "", newUrl);
		}

		$.ajax({
			url: "/shop/find",
			method: 'GET',
			data: uriComponent,
			statusCode: {
				500: function() {
					alert("Script exhausted");
				}
			},
			success: function(response) {
				if(response.indexOf("Something went wrong") !== -1) {
					alert("Sorry, the card system is down right now.")
					return;
				}
				if(isPaging) {
					$("#giftcards").append(response);
				} else {
					$("#giftcards").html(response);
				}
				self.addCatCounts();
				self.initPaging();
				self.loadingMoar = false;

				// Rebind preshop
				new PreShop;
			}
		});
	}

	// When a checkbox changes, we must update the other checkbox... e.g. if a user updates a checkbox with a wide browser and then resizes it to mobile size, we want the checkbox settings to be retained across both forms
	this.catChange = function(el, key, post) {
		if(el.hasClass("is-modal")) {
			var otherEl = $("#" + key + "_" + el.data("cat-id"));
		}
		else {
			var otherEl = $("#" + key + "--modal_" + el.data("cat-id"));
		}

		otherEl.prop("checked", el.prop("checked"));

		if(post) {
			self.load(false, true);
		}
	}

	// Individual checkbox change handler (e.g. on online / instore checkbox change) - again must update the other checkbox
	this.checkChange = function(el, key, post) {
		if(el.hasClass("is-modal")) {
			var otherEl = $("#" + key);
		}
		else {
			var otherEl = $("#" + key + "--modal");
		}

		otherEl.prop("checked", el.prop("checked"));

		if(post) {
			self.load(false, true);
		}
	}

	// When a request comes in, it has store counts for each category, here we add it to cat checkbox panels
	this.addCatCounts = function() {
		// Gather counts data from html response
		var counts = {}
		
		$(".cat-data").each(function() {
			var el = $(this);
			counts[el.data("cat-id")] = el.data("cat-count")
		})

		self.addCatCount(counts, ".cat-count");
	}

	// Hide and show cat counts based on number of results returned
	this.addCatCount = function(counts, selector) {
		// Insert data into the category form panel(s)
		$(selector).each(function() {
			var countSpan = $(this);
			var container = countSpan.closest(".box__content");
			var count = counts[countSpan.data("cat-id")];

			if(!count) {
				container.hide();
			} else {
				container.show();
			}
		});
	}

	this.buildRequest = function() {
		var out = {};

		// Extract category data
		$(".filter-cat").each(function() {
			var el = $(this);
			if(el.is(":checked")) {
				if(!out["cats"]) {
					out["cats"] = [];
				}
				out["cats"].push(el.data("cat-id"));
			}
		});

		var multi   = $("#filter-type_multi").is(":checked");
		
		if(multi) {
			out["multi_only"] = 1;
		}

		// Get the store name query param if there is one. choose visible input to extract the value
		var nameQuery = $("#nav-search").val();	
		if(nameQuery != "") {
			out["name"] = encodeURIComponent(nameQuery);
		}

		// Sorting parameter
		out["sort"] = $("#sort-order").val();

		return out;
	}

	var self = this;
	self.init();
}