/**
 * To sign on to EBCs website a form must be submitted with some values from the server.
 * If 'stop-auto-redirect' parameter is present, you can inspect the form values, this is helpful for debugging.
 */
export default class SsoForm {
	constructor() {
		this.form = $('.js-sso-form');

		setTimeout(() => {
			document.getElementById("trigger").className = "drawn";

			if(this.shouldAutoRedirect()) {
				this.form.submit();
			}
		}, 1000);
	}

	shouldAutoRedirect() {
		return location.href.indexOf('stop-auto-redirect=true') === -1;
	}
}