// PreShop.js

'use strict';

const buildIcon = require("Scripts/common/build-icon");

module.exports = PreShop;

function PreShop() {
	this.init = function() {
		// Preshop modal for choose reloadable vs digital so we know which SVM page to redirect to
		$(".js-giftcard-preshop").on('click', this.preshop);
	}

	this.preshop = function(e) {
		console.log("PreShop.preshop()", e);

		// Get the clicked link, modal and links we will be updating
		let link      = $(e.currentTarget);
		let modal     = $("#modal-pre-shop");
		let giftcards = link.find("> span");
		let html      = "";

		giftcards.each((i, v) => {
			let giftcard = $(v);

			html += 
				`<div class="col-12 col-md pb-4 pb-md-0">`+
					buildIcon(giftcard.data('icon'), {size:"fa-4x d-block mb-2 mx-auto", circle:true}) + 
					`<p class="mb-2">${giftcard.data('name')}</p>` +
					`<a target="_blank" href="${giftcard.data('uri')}" class="btn btn-default btn-sm mb-2">Shop now ${buildIcon('fa-angle-right')}</a>` +
					(giftcard.data('type') == "card" 
						? (giftcard.data('reloadable') 
							? `<p class="text-sm text-green">${buildIcon("fa-check")} Reloadable</p>` 
							: `<p class="text-sm text-red">${buildIcon("fa-xmark")} Not reloadable</p>`) 
					: "") +
				`</div>`;
		});

		modal.find(".js-giftcards").html(html);

		// Now show the modal
		modal.modal('show');

		// Close the modal once they've made a choice
		modal.find(".btn").off().on('click', () => {
			modal.modal('hide');
		});
	}

	this.init();
}