'use strict';

module.exports = UrlVars;

function UrlVars() {
	var out = {};
	var qPos = window.location.href.indexOf('?');

	if(qPos == -1) { // No query string
		return {};
	}
	
	var assignments = window.location.href.slice(qPos + 1).split('&');
	
	if(assignments.length == 0) { // No parameters!
		return {};
	}
	
	// Loop through variables in the qs
	for(var i = 0; i < assignments.length; i++) { 
		var bits = assignments[i].split('=');
		var key = bits[0];
		var value = bits[1];
		
		// Variable is array
		if(key.substring(key.length - 2, key.length) == "[]") { 
			key = key.substring(0, key.length - 2);
			if(!(key in out)) { 
				out[key] = []
			}
			out[key].push(value)

		}
		// Variable is just a scalar
		else { 
			out[key] = value;
		}
	}

	return out;
}