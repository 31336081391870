/*!
 * JavaScript for giftcards / instore site
 * Give as you Live Ltd
 */

// Import SASS/SCSS styles
import 'Sass/giftcards.scss';

// Import modules
const CharityChooser       = require("Scripts/common/charity-chooser");
const CorporateCharityList = require('Scripts/common/corporate-charity-list');
const Donations            = require('Scripts/common/donations');
const DropdownMenu         = require('Scripts/common/nav-dropdown');
const EmailSettings        = require('Scripts/common/email-settings');
const FacebookLogin        = require('Scripts/common/facebook-v2');
const FormUtils            = require('Scripts/common/form-utils');
const GdprConsentModal     = require('Scripts/common/gdpr-consent-modal');
const Global               = require('Scripts/common/global');
const Loader               = require('Scripts/common/loader');
const LoginSignup          = require('Scripts/common/login-signup');
const MobileMenu           = require('Scripts/common/nav-mobile');
const OwlCarousel          = require('Scripts/common/owl-carousel');
const PersonalDetails      = require('Scripts/common/personal-details');
const ShowHide             = require('Scripts/common/showhide');
const SupportTicket        = require('Scripts/common/support-ticket');

// Import Giftcards specific modules
const PreShop              = require('Scripts/giftcards/pre-shop');
const Shop                 = require('Scripts/giftcards/shop');
const SsoForm              = require('Scripts/giftcards/sso-form');

// Define groups of modules - the group name is set in your template ($jsModuleGroup). 
// This is just a nicer way to load JS on specific pages without looking at the path/params
// To initiate a group, use the #module macro. E.g. #module("my-group") would add 'my-group' from the groups below.
const moduleGroups = {
	// Available on every page
	'global':           [Global, DropdownMenu, MobileMenu, OwlCarousel, PreShop, ShowHide],
	// Available on specific pages
	'auth':             [LoginSignup, FacebookLogin],
	'charity-chooser':  [CharityChooser],
	'dashboard':        [GdprConsentModal],
	'donations':        [Donations],
	'email-settings':   [EmailSettings],
	'form-utils':       [FormUtils],
	'personal-details': [PersonalDetails],
	'public-corporate': [CorporateCharityList],
	'shop':             [Shop],
	'sso':              [SsoForm],
	'support-ticket':   [SupportTicket],
};

Loader.load(moduleGroups);